/**
 * Retrieves Youtube video ID from Youtube video url
 * @param {string} url - Youtube video url
 * @return {string} Youtube video ID or null if given link was incorrect
 * @see {@link https://gist.github.com/takien/4077195?permalink_comment_id=4812770|Github}
 */
export function getYoutubeVideoId(url: string): string | null {
  if (!url) {
    return null
  }

  // eslint-disable-next-line regexp/no-useless-escape, regexp/no-useless-non-capturing-group, regexp/optimal-quantifier-concatenation, regexp/strict, regexp/no-useless-flag
  const [a, , b] = url.replace(/(>|<)/gi, '').split(/^.*(?:(?:youtu\.?be(\.com)?\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/)
  if (b !== undefined) {
    return b.split(/[^\w-]/)[0]
  }
  else {
    return (/\s/.test(a) || a === '') ? null : a
  }
}

/**
 * Generates Youtube embed url based on given Youtube video ID
 * @param {string} videoId - Youtube video ID
 * @return {string} Youtube embed link ready to use within iframe
 */
export function getYoutubeVideoEmbedLink(videoId: string): string {
  return `https://www.youtube.com/embed/${videoId}?autoplay=1`
}
